import { CommonModule } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { IdLabelApiModel } from '../../../../generated-models';
import { BaseInputFormFieldComponent } from '../base-input-form-field';

@Component({
	selector: 'checkbox-group-input-form-field',
	standalone: true,
	imports: [CommonModule, MatCheckbox],
	templateUrl: './checkbox-group-input-form-field.component.html',
	styleUrl: './checkbox-group-input-form-field.component.scss'
})
export class CheckboxGroupInputFormFieldComponent extends BaseInputFormFieldComponent {
	public options = input<IdLabelApiModel[]>();
	public readonly = input<boolean>(false);
	public stringify = JSON.stringify;

	public columnWidth = computed(() => {
		const opts = this.options();
		if (!opts || opts.length == 0) {
			return '100%';
		}
		const longestWordLength = Math.max(...opts.map(x => x.label?.length ?? 0));
		return `min(${longestWordLength * 9}px + 45px, 100% - 45px)`;
	});

	constructor() {
		super('A multi-select checkbox group');
	}

	public isChecked(option: IdLabelApiModel): boolean {
		const values = this.formCtrl.value;
		// if any of the values or labels match, we count it.
		return Array.isArray(values) && values?.some(v => option.id == v || (option.label && option.label == v));
	}

	onCheckboxChange($event: MatCheckboxChange, changedId: number) {
		if (!this.readonly()) {
			const selectedValues: number[] = this.formCtrl.value ?? [];
			const nextValues = $event.checked
				? [...selectedValues, changedId]
				: selectedValues.filter(item => item !== changedId);

			nextValues.sort();
			this.formCtrl.setValue(nextValues);
		}
	}
}
